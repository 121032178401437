import React from 'react'
import classNames from 'classnames'

export default class BtcInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.format(this.props.value || ''),
    }
  }

  format(value) {
    value = value.replace(/,/g, '.')
    value = value.replace(/[^0-9.]/g, '')

    let parts = value.split('.')
    let amount = parts[0]

    if(parts.length >= 2) {
      let decimals = parts[1].substring(0, 8) // Up to 8 decimal places

      amount = amount + "." + decimals
    }

    return amount
  }

  onChange(event) {
    let value = this.format(event.target.value)

    this.setState({
      value: value,
    })
  }

  render() {
    return (
      <div className={classNames(
        'input-group',
        {
          'form-group-invalid': !!this.props.error,
        },
      )}>
        <input
          id={this.props.id || ''}
          type="tel"
          placeholder="0.00000000"
          className={classNames(
            'form-control',
            {
              'is-invalid': !!this.props.error,
            },
          )}
          onChange={this.onChange.bind(this)}
          value={this.state.value}
          {...this.props}
        />

        { this.renderError() }
      </div>
    )
  }

  renderError() {
    if(this.props.error) {
      return (
        <div class="invalid-feedback">
          { this.props.error }
        </div>
      )
    }
  }
}
